import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppState } from './../../reducers/index';
import {
  PlanType,
  PlanTypeWeight,
  SubscriptionCheckType,
  SubscriptionModel,
} from '../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { getAccountSubscription } from '../../reducers/account/account.service';

export const hasAtleastSubscriptionPlan = (plan: PlanType, subscriptionModel: SubscriptionModel) => {
  if (!subscriptionModel) {
    return false;
  }
  return subscriptionModel.plan.weight >= PlanTypeWeight[plan];
};

export const hasAtMostSubscriptionPlan = (plan: PlanType, subscriptionModel: SubscriptionModel) => {
  if (!subscriptionModel) {
    return false;
  }
  return subscriptionModel.plan.weight <= PlanTypeWeight[plan];
};

export const hasExactlySubscriptionPlan = (plan: PlanType, subscriptionModel: SubscriptionModel) => {
  if (!subscriptionModel) {
    return false;
  }
  return subscriptionModel.plan.weight === PlanTypeWeight[plan];
};

@Directive({
  selector: '[subscriptionPlan]',
  standalone: true,
})
export class SubscriptionPlanDirective implements OnInit, OnDestroy {
  private subscriptionPlan$: BehaviorSubject<PlanType> = new BehaviorSubject(null);
  public className: string;

  private subscription = new Subscription();

  private _context: SubscriptionPlanContext = new SubscriptionPlanContext();
  private _thenTemplateRef: TemplateRef<SubscriptionPlanContext> = null;
  private _elseTemplateRef: TemplateRef<SubscriptionPlanContext> = null;
  private _thenViewRef: EmbeddedViewRef<SubscriptionPlanContext> = null;
  private _elseViewRef: EmbeddedViewRef<SubscriptionPlanContext> = null;

  private checkType: SubscriptionCheckType = SubscriptionCheckType.AT_LEAST;

  @Input('subscriptionPlan')
  set plan(plan: PlanType) {
    this.subscriptionPlan$.next(plan);
  }

  @Input('subscriptionPlanCheckType')
  set planCheckType(planCheckType: SubscriptionCheckType) {
    this.checkType = planCheckType;
  }

  @Input('subscriptionPlanElse')
  set planElse(templateRef: TemplateRef<SubscriptionPlanContext>) {
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null;
    this._updateView();
  }

  @Input('subscriptionPlanClass')
  set planClass(className: string) {
    this.className = className;
  }

  constructor(
    private _viewContainer: ViewContainerRef,
    private store: Store<AppState>,
    templateRef: TemplateRef<SubscriptionPlanContext>,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) {
    this._thenTemplateRef = templateRef;
  }

  ngOnInit() {
    this.subscription.add(
      combineLatest([
        this.subscriptionPlan$.pipe(distinctUntilChanged()),
        this.store.pipe(select(getAccountSubscription)),
      ]).subscribe(([subscriptionPlan, accountSubscription]: [PlanType, SubscriptionModel]) => {
        switch (this.checkType) {
          case SubscriptionCheckType.AT_MOST:
            this._context.$implicit = hasAtMostSubscriptionPlan(subscriptionPlan, accountSubscription);
            break;
          case SubscriptionCheckType.EXACTLY:
            this._context.$implicit = hasExactlySubscriptionPlan(subscriptionPlan, accountSubscription);
            break;
          case SubscriptionCheckType.AT_LEAST:
            this._context.$implicit = hasAtleastSubscriptionPlan(subscriptionPlan, accountSubscription);
            break;
          default:
            this._context.$implicit = false;
            break;
        }
        this._updateView();
        this.cd.markForCheck();
      })
    );
  }

  private _updateView() {
    if (this._context.$implicit) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        this._elseViewRef = null;
        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
        }
      }
      if (this.className) {
        const rootElement = this._thenViewRef.rootNodes[0];
        if (rootElement) {
          const icons = rootElement.getElementsByTagName('icon') as HTMLCollection;
          if (icons && icons.length > 0) {
            this.renderer.setStyle(icons[1], 'display', 'none');
          }
        }
      }
    } else {
      // This case presents itself when access to the product feature is denied,
      // but a classname was provided. This means that the class should be applied
      // to the root element and the root element should be shown.
      // Also, if a plus icon is present this should be removed
      // and the lock icon should be shown.
      if (this.className) {
        if (!this._thenViewRef) {
          if (this._thenTemplateRef) {
            this._elseViewRef = null;
            this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
          }
        }

        const rootElement = this._thenViewRef.rootNodes[0];
        if (rootElement) {
          this.renderer.addClass(rootElement, this.className);
          this.renderer.setAttribute(rootElement, 'disabled', 'true');

          const icons = rootElement.getElementsByTagName('icon') as HTMLCollection;
          if (icons && icons.length > 0) {
            this.renderer.setStyle(icons[0], 'display', 'none');
          }
        }
      } else {
        if (!this._elseViewRef) {
          this._viewContainer.clear();
          this._thenViewRef = null;
          if (this._elseTemplateRef) {
            this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef, this._context);
          }
        }
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

export class SubscriptionPlanContext {
  public $implicit: any = null;
}
